var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Background", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the background of the property with the property "
        ),
        _c("code", [_vm._v("background-color")]),
        _vm._v(
          " or if you want to change the color of the popup you can use the property "
        ),
        _c("code", [_vm._v("background-color-popup")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorx,
                expression: "colorx",
              },
            ],
            attrs: { type: "color", name: "", value: "" },
            domProps: { value: _vm.colorx },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorx = $event.target.value
              },
            },
          }),
          _c(
            "vs-button",
            {
              attrs: {
                "text-color": "rgba(0,0,0,0.5)",
                color: _vm.colorx,
                type: "filled",
              },
              on: {
                click: function ($event) {
                  _vm.popupActive = true
                },
              },
            },
            [_vm._v("Open background popup")]
          ),
          _c(
            "vs-popup",
            {
              attrs: {
                "background-color": "rgba(152,152,152,.7)",
                "background-color-popup": _vm.colorx,
                title: "Background",
                active: _vm.popupActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupActive = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n\n      <input v-model="colorx" type="color" name="" value="">\n      <vs-button text-color="rgba(0,0,0,0.5)" :color="colorx" @click="popupActive=true" type="filled">Open background popup</vs-button>\n\n      <vs-popup background-color="rgba(255,255,255,.6)" :background-color-popup="colorx" class=""  title="Background" :active.sync="popupActive">\n        <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>\n      </vs-popup>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      colorx:"#def1d1",\n      popupActive: false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }