var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "popup-demo" } },
    [
      _c("popup-default"),
      _c("popup-inner-popup"),
      _c("popup-fullscreen"),
      _c("popup-background"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }