var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Fullscreen", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "the popup can be full screen you just have to add the property "
        ),
        _c("code", [_vm._v("fullscreen")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  _vm.popupActive = true
                },
              },
            },
            [_vm._v("Open fullscreen popup")]
          ),
          _c(
            "vs-popup",
            {
              attrs: {
                fullscreen: "",
                title: "fullscreen",
                active: _vm.popupActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupActive = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n      <vs-button @click="popupActive=true" color="primary">Open fullscreen popup</vs-button>\n      <vs-popup fullscreen title="fullscreen" :active.sync="popupActive">\n        <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n      </vs-popup>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      popupActive: false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }