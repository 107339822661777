var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Inner Popup", "code-toggler": "" } },
    [
      _c("p", [_vm._v("You can add one popup inside another")]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: {
                click: function ($event) {
                  _vm.popupActive2 = true
                },
              },
            },
            [_vm._v("Open Popup")]
          ),
          _c(
            "vs-popup",
            {
              attrs: {
                classContent: "popup-example",
                title: "Lorem ipsum dolor sit amet",
                active: _vm.popupActive2,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupActive2 = $event
                },
              },
            },
            [
              _c("vs-input", {
                staticClass: "inputx mb-3",
                attrs: { placeholder: "Placeholder" },
                model: {
                  value: _vm.value1,
                  callback: function ($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1",
                },
              }),
              _c("vs-input", {
                staticClass: "inputx mb-3",
                attrs: { disabled: "", placeholder: "Disabled" },
                model: {
                  value: _vm.value2,
                  callback: function ($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2",
                },
              }),
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function ($event) {
                      _vm.popupActive3 = true
                    },
                  },
                },
                [_vm._v("Open Inner Popup")]
              ),
              _c(
                "vs-popup",
                {
                  attrs: { title: "Inner popup", active: _vm.popupActive3 },
                  on: {
                    "update:active": function ($event) {
                      _vm.popupActive3 = $event
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n\n    <vs-button @click="popupActive2=true" color="primary" type="filled">Open Popup</vs-button>\n\n    <vs-popup classContent="popup-example" title="Lorem ipsum dolor sit amet" :active.sync="popupActive2">\n      <vs-input class="inputx mb-3" placeholder="Placeholder" v-model="value1" />\n      <vs-input disabled class="inputx mb-3" placeholder="Disabled" v-model="value2" />\n      <vs-button @click="popupActive3=true" color="primary" type="filled">Open Inner Popup</vs-button>\n\n      <vs-popup title="Inner popup" :active.sync="popupActive3">\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n      </vs-popup>\n    </vs-popup>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1: \'\',\n      value2: \'\',\n      popupActive2: false,\n      popupActive3: false\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }