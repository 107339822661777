var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To implement the popup we have the component "),
        _c("code", [_vm._v("vs-popup")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: {
                click: function ($event) {
                  _vm.popupActive = true
                },
              },
            },
            [_vm._v("Open Default popup")]
          ),
          _c(
            "vs-popup",
            {
              staticClass: "holamundo",
              attrs: {
                title: "Lorem ipsum dolor sit amet",
                active: _vm.popupActive,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupActive = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="demo-alignment">\n\n    <vs-button @click="popupActive=true" color="primary" type="border">Open Default popup</vs-button>\n\n    <vs-popup class="holamundo" title="Lorem ipsum dolor sit amet" :active.sync="popupActive">\n      <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>\n    </vs-popup>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      popupActive: false,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }